import React from 'react'
import App from 'next/app'
import Router from 'next/router'
import getConfig from 'next/config'
import * as Sentry from '@sentry/node'
import { RewriteFrames } from '@sentry/integrations'
import { SWRConfig } from 'swr'
import { Helmet } from 'react-helmet'
import { hotjar } from 'react-hotjar'
import ToastProvider from 'components/Toast'

import 'styles/index.css'
import 'styles/lighthouse-report.css'
import 'styles/lighthouse-report-override.css'
import 'styles/legal.css'
import 'react-circular-progressbar/dist/styles.css'
import 'tippy.js/dist/tippy.css'

import fetch from 'lib/fetch.js'
import * as analytics from 'utils/analytics'

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  const integrations = []
  if (
    process.env.NEXT_IS_SERVER === 'true' &&
    process.env.NEXT_PUBLIC_SENTRY_SERVER_ROOT_DIR
  ) {
    // For Node.js, rewrite Error.stack to use relative paths, so that source
    // maps starting with ~/_next map to files in Error.stack with path
    // app:///_next
    integrations.push(
      new RewriteFrames({
        iteratee: (frame) => {
          frame.filename = frame.filename.replace(
            process.env.NEXT_PUBLIC_SENTRY_SERVER_ROOT_DIR,
            'app:///'
          )
          frame.filename = frame.filename.replace('.next', '_next')
          return frame
        },
      })
    )
  }

  Sentry.init({
    enabled: process.env.NODE_ENV === 'production',
    integrations,
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    environment: process.env.STAGE,
    release: process.env.NEXT_PUBLIC_COMMIT_SHA,
  })
}

export default class MyApp extends App {
  componentDidMount() {
    if (
      process.env.NEXT_PUBLIC_GA_TRACKING_ID &&
      process.env.NODE_ENV === 'production'
    ) {
      analytics.init()
      analytics.pageview()
      Router.events.on('routeChangeComplete', (url) => analytics.pageview(url))
    }

    if (
      process.env.NEXT_PUBLIC_HOTJAR_SITE_ID &&
      process.env.NODE_ENV === 'production'
    ) {
      hotjar.initialize(process.env.NEXT_PUBLIC_HOTJAR_SITE_ID, 6)
    }
  }

  render() {
    const { Component, pageProps } = this.props

    // Workaround for https://github.com/zeit/next.js/issues/8592
    const { err } = this.props
    const modifiedPageProps = { ...pageProps, err }

    return (
      <>
        <Helmet
          htmlAttributes={{ lang: 'en' }}
          titleTemplate="%s | Lighthouse Metrics"
          defaultTitle="Global performance insights for your site | Lighthouse Metrics"
          meta={[
            {
              name: 'viewport',
              content: 'width=device-width, initial-scale=1',
            },
            {
              name: 'description',
              content:
                "Easy insights for your site's performance. Save your time by running tests from multiple locations to get the valuable insight you need.",
            },
            {
              name: 'twitter:creator',
              content: '@chriswdmr',
            },
            {
              name: 'og:image',
              content: 'https://lighthouse-metrics.com/img/og-image.png',
            },
          ]}
        />
        <SWRConfig
          value={{
            fetcher: fetch,
          }}
        >
          <ToastProvider>
            <Component {...modifiedPageProps} err={err} />
          </ToastProvider>
        </SWRConfig>
      </>
    )
  }
}
