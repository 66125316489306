export default async function myFetch(url, config = {}) {
  const headers = { 'Content-Type': 'application/json' }

  if (config.body) {
    config.headers = {
      ...headers,
      ...config.headers,
    }

    if (config.headers?.['Content-Type'] === 'application/json') {
      config.body = JSON.stringify(config.body)
    }
  }

  if (config.method) {
    config.method = config.method.toUpperCase()
  }

  const res = await fetch(url.startsWith('http') ? url : '/api' + url, config)
  const data = await res.json()

  if (res.ok) {
    return data
  } else {
    if (res.status === 401) {
      if (typeof window !== 'undefined') {
        window.location.href = '/api/auth/login'
      }
    }

    return Promise.reject(data)
  }
}
