import React, { createContext, useState, useContext } from 'react'
import Transition from 'components/Transition'

export const ToastContext = createContext(null)

export const useToast = () => {
  const { openToast, closeToast } = useContext(ToastContext)

  return [openToast, closeToast]
}

export default function Toast({ children }) {
  const [open, setOpen] = useState(false)
  const [text, setText] = useState('')
  const [type, setType] = useState('success')

  const triggerToast = (text, type) => {
    setText(text)
    setType(type)
    setOpen(true)
  }

  const openToast = (text, { type } = { type: 'success' }) => {
    triggerToast(text, type)

    setTimeout(() => {
      closeToast()
    }, 3000)
  }

  const closeToast = () => {
    setOpen(false)
  }

  const Icon = () => {
    const baseClass = 'w-6 h-6 flex-shrink-0 mr-2'

    switch (type) {
      case 'success':
        return (
          <svg
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            viewBox="0 0 24 24"
            stroke="currentColor"
            className={`${baseClass} text-green-400`}
          >
            <path d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
          </svg>
        )
      case 'error':
        return (
          <svg
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            viewBox="0 0 24 24"
            stroke="currentColor"
            className={`${baseClass} text-red-400`}
          >
            <path d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
          </svg>
        )
      default:
        return
    }
  }

  return (
    <ToastContext.Provider value={{ openToast, closeToast }}>
      {children}
      <div className="fixed inset-0 flex items-end justify-center mt-16 px-4 py-6 pointer-events-none sm:p-6 sm:pt-7 sm:items-start sm:justify-end">
        <Transition
          show={open}
          enter="transform ease-out duration-300 transition"
          enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
          enterTo="translate-y-0 opacity-100 sm:translate-x-0"
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto">
            <div className="rounded-lg shadow-xs overflow-hidden">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="w-0 flex-1 flex items-center">
                    <Icon />
                    <p className="w-0 flex-1 text-sm leading-5 font-medium text-gray-900">
                      {text}
                    </p>
                  </div>
                  <div className="ml-4 flex-shrink-0 flex">
                    <button
                      className="inline-flex text-gray-400 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150"
                      onClick={closeToast}
                    >
                      <svg
                        className="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Transition>
      </div>
    </ToastContext.Provider>
  )
}
