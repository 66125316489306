import ReactGA from 'react-ga'

export const init = () => {
  ReactGA.initialize(process.env.NEXT_PUBLIC_GA_TRACKING_ID)
  ReactGA.set({ anonymizeIp: true })
}

export const pageview = (url) => {
  url = url || window.location.pathname + window.location.search

  ReactGA.set({ page: url })
  ReactGA.pageview(url)
}

export const event = (category = '', action = '') => {
  if (category && action) {
    ReactGA.event({ category, action })
  }
}
